#root {

  height: 100vh;
  width: 100vw;
}

.hud {
  position: absolute;
  bottom: 0;
  width: 100%;

}

.hud-buttons {
  position: absolute;
  top: -35px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}